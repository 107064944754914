/* Footer.css */

.footer {
    background-color: #000000; /* Fundo escuro */
    padding: 20px 0; /* Padding vertical */
    color: #fff; /* Texto em branco */
    text-align: center; /* Centraliza o texto */
}

.footer-content {
    max-width: 800px; /* Limita a largura máxima do conteúdo */
    margin: 0 auto; /* Centraliza o conteúdo */
    display: flex; /* Flexbox para centralizar elementos */
    flex-direction: column; /* Coloca ícones e texto em coluna */
    align-items: center; /* Alinha o conteúdo ao centro */
    justify-content: center; /* Centraliza verticalmente */
}

.social-icons {
    display: flex; /* Flexbox para os ícones */
    gap: 15px; /* Espaçamento entre ícones */
    margin-bottom: 10px; /* Espaçamento abaixo dos ícones */
}

.social-icon {
    font-size: 1.5rem; /* Tamanho dos ícones */
    color: #ffdc00; /* Cor dos ícones em amarelo */
    transition: color 0.3s ease; /* Transição suave para hover */
}

.social-icon:hover {
    color: #e5c700; /* Cor dos ícones ao passar o mouse */
}

.footer-text {
    font-size: 0.9rem; /* Tamanho do texto menor */
    color: #ccc; /* Texto em cinza claro */
}

/* Header.css */

header.hidden {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}

header {
    width: auto;
    background-color: #000000;
    padding: 5px 15px;
    position: fixed; /* Fixar a barra no topo */
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: transform 0.3s ease-in-out; /* Transição suave para esconder */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 50px;
}

nav.nav-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
}

nav.scrolling {
    background-color: #000;
    padding: 5px 10px;
}

.logo-text {
    font-size: 1.3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.logo-white {
    color: #fff;
}

.logo-yellow {
    color: #ffdc00;
}

.menu {
    list-style: none;
    display: flex;
    gap: 15px;
    margin: 0;
    align-items: center;
}

.menu li {
    margin: 0;
}

.menu a {
    color: #fff;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    padding-bottom: 5px;
    position: relative;
}

.menu a:hover,
.menu a.active {
    color: #ffdc00;
}

.menu a:hover::after,
.menu a.active::after {
    width: 100%;
    left: 0;
}

.menu a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: #ffdc00;
    bottom: 0;
    left: 50%;
    transition: width 0.3s ease, left 0.3s ease;
}

.resume-button {
    border: 1px solid #ffdc00;
    padding: 3px 8px;
    border-radius: 3px;
    color: #fff;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 0.85rem;
    margin-left: 10px;
    text-transform: lowercase;
    position: relative;
    overflow: hidden;
}

.resume-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffdc00;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 0;
}

.resume-button:hover::after {
    transform: translateX(0);
}

.resume-button:hover {
    color: #111;
}

.resume-button span {
    position: relative;
    z-index: 1;
}

.menu-icon {
    display: none;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 20px;
    transition: transform 0.3s ease;
}

.icon-bar {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transition: all 0.3s ease;
}

.icon-bar:nth-child(1) {
    top: 0;
}

.icon-bar:nth-child(2) {
    top: 8px;
}

.icon-bar:nth-child(3) {
    top: 16px;
}

.menu-icon.open .icon-bar:nth-child(1) {
    transform: rotate(45deg);
    top: 8px;
}

.menu-icon.open .icon-bar:nth-child(2) {
    opacity: 0;
}

.menu-icon.open .icon-bar:nth-child(3) {
    transform: rotate(-45deg);
    top: 8px;
}

.menu.open {
    display: flex; /* Exibe o menu quando aberto */
}

/* Media Queries para Responsividade */
@media (max-width: 768px) {
    .menu {
        display: none; /* Esconde o menu por padrão em telas pequenas */
        flex-direction: column;
        background-color: #111;
        position: absolute;
        top: 50px;
        right: 0;
        width: 200px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        padding: 10px 0;
        gap: 10px;
    }

    .menu.open {
        display: flex; /* Exibe o menu quando aberto */
    }

    .menu li {
        text-align: center;
        margin: 0;
    }

    .menu a {
        padding: 10px;
        font-size: 1rem;
    }

    .menu-icon {
        display: block; /* Exibe o ícone do menu em telas pequenas */
    }

    nav.nav-container {
        padding: 5px 10px;
    }
}

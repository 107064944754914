/* Ola.css */

.hi-section {
    background-color: #000000; /* Fundo escuro */
    color: #fff; /* Texto em branco */
    padding: 60px 20px;
}

.section-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ffdc00; /* Título em amarelo */
    text-align: left; /* Alinha o título à esquerda */
}

.hi-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px; /* Aumenta a largura máxima da área de conteúdo */
    margin: 0 auto;
    flex-wrap: wrap; /* Permite quebrar em linhas quando a tela é pequena */
}

.hi-text {
    flex: 1;
    padding-right: 20px;
    font-size: 1rem; /* Reduz o tamanho da fonte para compactar o texto */
    line-height: 1.4; /* Ajusta a altura da linha para melhorar a legibilidade */
    color: #ccc; /* Texto em cinza claro */
    max-width: 700px; /* Aumenta o limite da largura do texto */
}

.hi-image {
    flex: 1;
    display: flex;
    justify-content: center;
}

.hi-image img {
    max-width: 150px; /* Reduz a largura máxima da imagem */
    height: auto;
    border-radius: 8px; /* Bordas arredondadas */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.skills-section {
    margin-top: 20px; /* Espaçamento acima da seção de habilidades */
    text-align: left; /* Alinha o texto à esquerda */
}

.skills-title {
    font-size: 1rem; /* Tamanho do texto das habilidades */
    margin-bottom: 10px;
    color: #fff; /* Título das habilidades em branco */
}

.skills-list {
    list-style: none; /* Remove os pontos da lista */
    padding: 0;
}

.skill-item {
    margin-bottom: 15px; /* Espaçamento entre os itens */
}

.skill-topic {
    font-size: 1.1rem; /* Tamanho do texto dos tópicos */
    color: #ffdc00; /* Cor dos tópicos em amarelo */
    margin-bottom: 5px;
}

.skill-details {
    font-size: 0.9rem; /* Tamanho do texto das habilidades */
    color: #ccc; /* Texto em cinza claro */
    line-height: 1.5; /* Altura da linha para melhor legibilidade */
}

@media (max-width: 768px) {
    .hi-content {
        flex-direction: column; /* Alinha o texto e a imagem verticalmente em telas menores */
    }
}

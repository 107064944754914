/* Portfolio.css */

.portfolio {
    background-color: #000000; /* Fundo escuro */
    color: #fff; /* Texto branco */
    padding: 40px 20px;
}

.portfolio-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.portfolio-item {
    background-color: #222; /* Fundo do item do portfólio */
    border: 1px solid #444; /* Borda do item */
    border-radius: 8px; /* Bordas arredondadas */
    padding: 20px;
    width: calc(33.333% - 20px); /* Largura dos itens, 3 por linha */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
}

.portfolio-item:hover {
    transform: scale(1.05); /* Efeito de zoom ao passar o mouse */
}

.portfolio-icon {
    font-size: 2rem; /* Tamanho do ícone */
    color: #ffdc00; /* Cor do ícone em amarelo */
    margin-bottom: 10px;
}

.portfolio-text {
    flex: 1;
}

.portfolio-text h3 {
    font-size: 1.2rem; /* Tamanho do título */
    margin-bottom: 10px;
}

.portfolio-text p {
    font-size: 0.9rem; /* Tamanho da descrição */
    margin-bottom: 10px;
}

.technologies {
    font-size: 0.8rem; /* Tamanho da fonte das tecnologias */
    color: #ffdc00; /* Cor em amarelo */
    margin-bottom: 10px;
    padding: 10px;
}

.tech-item {
    display: inline-block;
    margin-right: 5px; /* Espaçamento entre tecnologias */
    white-space: nowrap; /* Impede quebra de linha dentro do item */
}

.project-link {
    font-size: 0.9rem; /* Tamanho do botão */
    color: #ffdc00; /* Cor do texto do botão */
    text-decoration: none;
    border: 1px solid #ffdc00; /* Borda do botão */
    border-radius: 4px; /* Bordas arredondadas */
    padding: 8px 16px; /* Padding do botão */
    transition: background-color 0.3s, color 0.3s;
}

.project-link:hover {
    background-color: #ffdc00; /* Cor de fundo do botão ao passar o mouse */
    color: #000; /* Cor do texto ao passar o mouse */
}

/* ContactForm.css */

.contact-section {
    padding: 40px 20px;
    background-color: #000000; /* Fundo escuro */
    color: #fff; /* Texto em branco */
    min-height: 100vh; /* Ocupa a altura total da viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Alinha o conteúdo verticalmente */
}

.contact-container {
    max-width: 500px; /* Ajusta a largura máxima do container */
    width: 100%; /* Ocupa toda a largura disponível */
    background-color: #111; /* Fundo mais escuro */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.section-title {
    font-size: 2rem;
    margin-bottom: 20px; /* Ajusta a margem inferior do título */
    color: #ffdc00; /* Título em amarelo */
    text-align: left;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

label {
    font-size: 0.9rem;
    color: #ffdc00; /* Cor do rótulo em amarelo */
}

input[type="text"],
input[type="email"],
textarea {
    padding: 10px; /* Ajusta o padding dos campos */
    font-size: 0.9rem;
    color: #fff;
    background-color: #333; /* Fundo dos campos */
    border: none;
    border-radius: 4px;
    outline: none;
    transition: background-color 0.3s ease;
    width: 100%; /* Campos ocupam toda a largura disponível */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    background-color: #444; /* Fundo dos campos no foco */
}

textarea {
    resize: vertical; /* Permite que o usuário redimensione verticalmente */
}

.submit-btn {
    padding: 10px 20px; /* Ajusta o padding do botão */
    font-size: 1rem;
    color: #111;
    background-color: #ffdc00; /* Fundo do botão em amarelo */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.submit-btn:hover {
    background-color: #e5c700; /* Fundo do botão ao passar o mouse */
}

.success-message {
    margin-top: 15px; /* Margem superior para a mensagem de sucesso */
    color: #ffdc00; /* Cor da mensagem de sucesso */
}

/* Adiciona um efeito de foco no botão */
.submit-btn:focus {
    outline: 2px solid #e5c700; /* Adiciona uma borda visível ao focar no botão */
    outline-offset: 2px;
}

/* Adiciona um efeito sutil ao passar o mouse sobre o botão */
.submit-btn:active {
    background-color: #f3d800; /* Fundo do botão ao clicar */
    transform: scale(0.98); /* Leve redução no tamanho do botão ao clicar */
}

/* Adiciona espaçamento ao redor do formulário em dispositivos móveis */
@media (max-width: 600px) {
    .contact-section {
        padding: 20px 10px;
    }

    .contact-container {
        padding: 20px;
    }
}

/* App.css */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Source Code Pro', monospace;
    line-height: 1.6;
    background-color: #000; /* Fundo preto */
    color: #fff; /* Texto branco */
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Source Code Pro', monospace;
    color: #ffdc00; /* Títulos em amarelo */
}

a {
    color: #ffdc00; /* Links em amarelo */
    text-decoration: none;
    transition: color 0.3s;
}

a:hover {
    color: #fff; /* Links ficam brancos ao passar o mouse */
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

/* Remove margens e ajusta o padding das seções */
section {
    text-align: left;
    margin: 0; /* Remove margens externas */
    padding: 40px 20px; /* Ajusta o padding interno */
}

/* Ajuste específico para cada seção, se necessário */
#hi {
    padding: 5px 20px; /* Ajusta o padding para a seção "Olá" */
}

#experience {
    padding: 20px 20px; /* Ajusta o padding para a seção "Experiência" */
}

#portfolio {
    padding: 5px 30px; /* Ajusta o padding para a seção "Portfólio" */
}

#contact {
    padding: 30px 20px; /* Ajusta o padding para a seção "Contato" */
}

/* Se houver um espaçamento específico para o header ou footer */
header {
    margin-bottom: 3px; /* Ajusta o espaçamento inferior do header */
}


/* SocialLinks.css */

.social-links {
    position: fixed;
    top: 50%;
    left: 5px; /* Distância da lateral esquerda */
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000; /* Certifique-se de que está acima de outros elementos */
}

.social-links a {
    margin: 10px 0;
    color: #ffdc00; /* Cor dos ícones */
    transition: color 0.3s;
    text-decoration: none;
}

.social-links a:hover {
    color: #fff; /* Cor ao passar o mouse */
}

.social-icon {
    font-size: 1rem; /* Tamanho dos ícones */
}

/* Experience.css */

.experience-section {
    background-color: #000000; /* Fundo escuro */
    padding: 50px 20px;
    color: #fff; /* Texto branco para contraste */
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.section-title {
    font-size: 2.5rem;
    color: #ffdc00; /* Título em amarelo */
    text-align: center;
    margin-bottom: 50px;
}

.experience-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.company-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 40%; /* Ajuste para o tamanho desejado */
}

.company-item {
    padding: 10px 15px;
    background-color: #222; /* Fundo dos itens */
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 500;
}

.company-item:hover,
.company-item.active {
    background-color: #333;
    color: #ffdc00;
}

.experience-details {
    width: 55%; /* Diminuindo o tamanho do campo */
    background-color: #222;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.role {
    font-size: 1.5rem;
    color: #fff;
    margin: 0 0 5px;
}

.duration {
    font-size: 0.9rem;
    color: #aaa;
    margin-bottom: 15px;
    display: block;
}

.responsibilities {
    list-style: none;
    padding: 0;
    margin: 0;
}

.responsibilities li {
    font-size: 0.95rem;
    color: #ddd;
    margin-bottom: 8px;
    line-height: 1.4;
}

/* Responsividade */
@media (max-width: 768px) {
    .experience-content {
        flex-direction: column;
    }

    .company-list,
    .experience-details {
        width: 100%;
    }

    .section-title {
        font-size: 2rem;
    }

    .role {
        font-size: 1.3rem;
    }
}

/* Hero.css */

.hero {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Alinha o contêiner à esquerda */
    text-align: left; /* Alinha o texto à esquerda */
    padding-left: 50px; /* Adiciona um espaçamento à esquerda */
    color: #fff; /* Texto em branco como padrão */
}

.hero-content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px;
    border-radius: 5px;
    max-width: 600px; /* Limita a largura do contêiner */
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    line-height: 1.3;
}

.white-text {
    color: #fff; /* Fonte branca */
}

.yellow-text {
    color: #ffdc00; /* Fonte amarela */
}

.subtext {
    margin-left: 60px; /* Espaço à direita */
    font-size: 1rem; /* Fonte menor */
    color: #ccc; /* Texto cinza claro */
    margin-top: 20px; /* Espaço entre o h1 e o texto */
    max-width: 400px; /* Ajuste opcional de largura */
    line-height: 1.5;
}